import React, { Fragment } from 'react'
import Link from 'gatsby-link'
import moment from 'moment'

function filterByPublishTo(arr, publishTo) {
  return arr.filter((obj) =>
    obj.publishTo.some((publish) => publish.url === publishTo)
  )
}

function getPreviousType(slug) {
  if (!slug.includes("/")) {
    return "news"
  }

  if (slug.includes("/blogs/")) {
    return "blogs"
  }

  if (slug.includes("/solutions/")) {
    return "solutions"
  }

  if (slug.includes("/products/")) {
    return "products"
  }

  if (slug.includes("/static/")) {
    return "resources"
  }
}


class RelatedNews extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      previousType: "",
    }
  }

  componentDidMount() {
    const { data } = this.props
    let previousType = []
    filterByPublishTo(data, this.props.siteUrl).forEach((item) => {
      const type = getPreviousType(item.slug)
      previousType.push(type)
    })
    this.setState({ previousType })
  }

  render() {
    const { previousType } = this.state
    return (
      <div className="news-sidebar">
        <div className="widget widget-recent-post">
          {filterByPublishTo(this.props.data, this.props.siteUrl).map((item, index) => (
            <Fragment key={index}>
              {(previousType[index] === "news" && (index === 0 || previousType[index-1] !== "news")) && (
                <h3 className={`font-alt font-w-700 letter-spacing-1 title-xs-medium title-large pb-2 ${index === 0 ? "mt-0" : "mt-5"}`}>
                  Related news
                </h3>
              )}

              {(previousType[index] === "blogs" && (index === 0 || previousType[index-1] !== "blogs")) && (
                <h3 className={`font-alt font-w-700 letter-spacing-1 title-xs-medium title-large pb-2 ${index === 0 ? "mt-0" : "mt-5"}`}>
                  Related blogs
                </h3>
              )}

              {(previousType[index] === "solutions" && (index === 0 || previousType[index-1] !== "solutions")) && (
                <h3 className={`font-alt font-w-700 letter-spacing-1 title-xs-medium title-large pb-2 ${index === 0 ? "mt-0" : "mt-5"}`}>
                  Related solutions
                </h3>
              )}

              {(previousType[index] === "products" && (index === 0 || previousType[index-1] !== "products")) && (
                <h3 className={`font-alt font-w-700 letter-spacing-1 title-xs-medium title-large pb-2 ${index === 0 ? "mt-0" : "mt-5"}`}>
                  Related products
                </h3>
              )}

              {(previousType[index] === "resources" && (index === 0 || previousType[index-1] !== "resources")) && (
                <h3 className={`font-alt font-w-700 letter-spacing-1 title-xs-medium title-large pb-2 ${index === 0 ? "mt-0" : "mt-5"}`}>
                  Customer success stories
                </h3>
              )}


              <div className="media-body">
                <p className="font-alt font-w-400 letter-spacing-1 text-xs-medium text-medium py-0 mb-2">
                  {item.slug.substring(0,1) !== "/" ?
                    <Link to={`/news/${item.slug}/`} className="effect-noline">{item.title}</Link>
                    : 
                    <Link to={item.slug} className="effect-noline">{item.title}</Link>
                  }
                </p>
                
                {item.slug.substring(0,1) !== "/" &&
                  <div className="news-article-info font-alt font-w-400 letter-spacing-1 text-small pb-4">
                      {moment(item.date).format('YYYY/MM/DD')}
                  </div>
                }
              </div>
            </Fragment>
          ))}
        </div>
      </div>
    )
  }
}

export default RelatedNews